import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { fetchCount } from './counterAPI';

const headerHeight = 135
const avatarRadius= 100

const initialState = {
  viewportSize:{width:0,height:0},
  pointerOffset:0,
  avatarRadius:avatarRadius,
  headerHeight:headerHeight,
  dragPosition:{x:0,y:0},
  origin:{y: headerHeight-(avatarRadius / 2)}
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
/*export const incrementAsync = createAsyncThunk(
  'counter/fetchCount',
  async (amount) => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);*/

export const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPointerOffset: (state, action)  => {
      state.pointerOffset = action.payload
    },
    setViewportSize: (state, action)  => {
      
      const width = action.payload.width;
      const height = action.payload.height


      console.log(width, height)
      //const width = window.innerWidth;
      //const height = window.innerHeight
//assessment-page

      state.viewportSize.width = width
      state.viewportSize.height = height
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  /*extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value += action.payload;
      });
  },*/
});

export const { setViewportSize, setPointerOffset } = assessmentSlice.actions;

export const assessmentSelectors = {}
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/*export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};*/

export default assessmentSlice.reducer;
