import { HTTP } from 'api'

const basePath = "/account/";
//const basePath = "";

export default {

  addPassword(userId, code, password) {
    const p = {
      password,
      confirmPassword: password,
      code,
      userId:userId + ''
    }
    return HTTP.patch(basePath.concat('AddPassword'), p);
  },

  resetPassword(userId, code, password) {
    const p = {
      password,
      confirmPassword: password,
      code,
      userId
    }
    return HTTP.post(basePath.concat('ResetPassword'), p);
  },

  login(username, password) {

    const p = {
      password,
      username
    }
    return HTTP.post(basePath.concat('login'), p);
  },

  logout() {
    const p = {}
    return HTTP.post(basePath.concat('logout'), p);
  },

  forgotPassword(email) {

    const p = {
      "email": email
    }

    return HTTP.post(basePath.concat('ForgotPassword'), p);


  }
}