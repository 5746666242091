import { connect } from "react-redux";
import { IconCloseSquare, IconInfoCircle, IconHand } from 'components/icons'
import { mainSelectors } from "stores/mainSlice";
import { motion, AnimatePresence } from "framer-motion"
import { setViewportSize, setPointerOffset } from "stores/assessmentSlice";
import assessmentSessionService from 'api/assessmentSessionService';
import i18next from "i18next";
import React, { Component } from 'react';
import {storeActions} from 'stores/storeHelpers'

import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Card, Container, CardBody,
  CardTitle, CardSubtitle
} from 'reactstrap';

import { BackgroundCircles } from './BackgroundCircles'
import { CompetencyOverlay } from './CompetencyOverlay'
import { DragItem } from './DragItem'
import { OverlayCircle } from './OverlayCircle'

import { ATRAIN_ROUTES, getRoute } from 'routes';
import AnimatedPopup from 'components/AnimatedPopup';

export class CompetencyItemInner extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.overlayCircleRef = React.createRef();
    this.myContainer = React.createRef();

    this.dragItemsRef = [];

    this.state = {
      headerHeight: 135,
      dragItemHeight: 30,
      foo: '',
      curItem: 0,
      showOverlay: true,
      isDragging: false,
      canDrag: true,
      showHint: true,
      markerScores: this.createBlankScores(),
      farthestPoint: 0,
      score: 0,
      radius: 0,
      dragPosition: { x: 0, y: 0 },
      isInfoDialogVisible:false,
      isLeaveAssessmentDialogVisible:false
    }

    //    this.props.setViewportSize(this.myContainer)
  }

  handleRepositionStart(idx, boundings) {
    const radius = this.calcucateDistance(boundings)

    this.setState({
      isRepositioning: true,
      radius
    });

    //this.setDistanceAndScore(idx,boundings)
    //this.props.setDragPosition(boundings)

  }

  handleDragEnd(idx, boundings, item) {
    //console.log('handleDragEnd',idx,a,b,c)

    let newIdx = idx * 1 + 1
    if (newIdx > this.state.curItem) {
      this.setState({
        curItem: newIdx
      });
    }

    const score = this.calcucateScore(boundings)

    const markerScores = this.state.markerScores.map(ms => {
      console.log(ms.behaviourMarkerId, item)

      if (ms.behaviourMarkerId == item.id) {
        return { ...ms, score }
      } else {
        return ms
      }
    })

    console.log(markerScores)
    this.setState({
      isDragging: false,
      isRepositioning: false,
      score,
      markerScores
    });

  }
  ////////////////////
  //handleDragStart(idx,event, boundings, offsetTop){
  handleDragStart(idx, boundings, item) {
    //this.props.setPointerOffset(offsetTop - info.point.y)
    //this.props.setPointerOffset(event.pageY - boundings.y)
    this.setState({
      isDragging: true,
      showHint: false
    });
  }

  handleStartCardDismiss() {
    this.setState({
      showOverlay: false
    });
  }

  handleBtnNext() {
    storeActions.setIsLoading(true)
    assessmentSessionService.saveCompetencyStep(this.state.markerScores, this.props.assessmentSessionId).then(resp => {
      this.props.onNext()
    })
    .catch(err =>{
      storeActions.setErrorMessage(true,err.message)
    })
    .finally(()=>{storeActions.setIsLoading(false);})

  }

  getBoundedPos(boundings, applyOffset) {


    const r = this.myRef.current
    const { headerHeight, dragItemHeight } = this.state
    const myMinY = headerHeight - (dragItemHeight / 2)
    const myMaxY = r.offsetHeight + headerHeight + (dragItemHeight / 2)

    const viewportOffset = applyOffset ? ((window.innerWidth - r.offsetWidth) / 2) : 0 //Nel caso desktop, la finestra potrebbe essere più grande dell'area di calcolo

    let myX = boundings.x - viewportOffset;
    //let myX = boundings.x;
    let myY = boundings.y;

    if (myX < 0)
      myX = 0

    if (myX > r.offsetWidth)
      myX = r.offsetWidth


    if (myY < myMinY)
      myY = myMinY

    if (myY > myMaxY)
      myY = myMaxY


    return { x: myX, y: myY }
  }

  calcucateScore(boundings) {
    const r = this.myRef.current
    const { headerHeight } = this.state
    const pos = this.getBoundedPos(boundings, true)

    //myY = (myY < 135 - 15) ? 135 - 15: myY
    //myY = (myY > r.offsetHeight + 135 + 15) ? r.offsetHeight + 135 + 15: myY

    //console.log({boundings, pos, row:r.offsetWidth, r})

    const w = Math.abs(pos.x + (boundings.width / 2) - (r.offsetWidth / 2))
    const h = Math.abs(pos.y - headerHeight)



    let dist = Math.hypot(w, h)
    let score = 100 - ((dist / this.state.farthestPoint) * 100);
    //console.log({boundings, pos, row:r.offsetWidth, r, w, h, dist, score})

    if (score < 0) {
      score = 0
    } else if (score > 100) {
      score = 100
    }

    return score
    /*this.setState({
      score
    });*/

  }

  calcucateDistance(boundings) {
    const { dragItemHeight } = this.state
    const pos = this.getBoundedPos(boundings)

    let radius = Math.hypot((pos.x + (boundings.width / 2)) - (this.props.assessmentStore.viewportSize.width / 2), (pos.y) - this.props.assessmentStore.origin.y + (dragItemHeight / 2))

    return radius
  }

  setDistanceAndScoreXXX(idx, boundings) {

    const myScore = this.calcucateScore(boundings)
    const myRadius = this.calcucateDistance(boundings)

    let myDragPosition = { x: boundings.x, y: boundings.y }

    this.setState({
      score: myScore,
      radius: myRadius,
      dragPosition: myDragPosition
    });

    /*
    const r = this.myRef.current

    let myX = boundings.x;
    let myY = boundings.y;


    console.log('myY',myY)
    myX = (myX < 0) ? 0 : myX;
    myX = (myX > r.offsetWidth) ? r.offsetWidth : myX;

    //myY = (myY < 135) ? 135 : myY
    //myY = (myY > r.offsetHeight + 135 + 15) ? r.offsetHeight + 135 + 15: myY


    myY = (myY < 135 - 15) ? 135 - 15: myY
    myY = (myY > r.offsetHeight + 135 + 15) ? r.offsetHeight + 135 + 15: myY



    const w = Math.abs(myX + (boundings.width / 2) - (r.offsetWidth / 2))
    const h = Math.abs(myY - 135)

  let dist = Math.hypot(w,h)
  console.log('dist', dist, 'XY', myX, myY)
  let score = 100 - ((dist / this.state.farthestPoint)  * 100);

  if(score < 0 ){
    score = 0
  } else if (score > 100){
    score = 100
  }

  console.log(myX, myY, this.state.farthestPoint, score)


  let radius = Math.hypot((myX + (boundings.width / 2) )- (this.props.assessmentStore.viewportSize.width / 2), (myY) - this.props.assessmentStore.origin.y + 15)

  let dragPosition = {x:boundings.x, y:boundings.y}
console.log('radius',radius)
  this.setState({
    score,
    radius,
    dragPosition
  });
  */
    //console.log('handleDrag')
  }

  handleDrag(idx, boundings, item) {

    //console.log('handleDrag > boundings', JSON.stringify(boundings))


    if (this.state.dragPosition.y != boundings.y || this.state.dragPosition.x != boundings.x) {
      const radius = this.calcucateDistance(boundings)
      let dragPosition = { x: boundings.x, y: boundings.y }

      this.setState({
        radius,
        dragPosition
      })
      //this.setDistanceAndScore(idx,boundings)

      //this.props.setDragPosition(boundings)

    }
  }

  setFarthestPoint() {
    const ref = this.myRef.current;

    //const width = ref.offsetWidth
    const height = ref.offsetHeight
//console.log('height',height)
//console.log('ref',ref)
    /*console.log({width, height})

    const x = (width / 2)
    const y = height - 30 // 30 è l'altezza del div draggabile

    console.log('wh:',x,y, height)
    const farthestPoint = Math.hypot(x, y);
*/
    const farthestPoint = height - 30;
    //console.log('farthestPoint',farthestPoint)

    this.setState({
      farthestPoint
    });
  }

  handleWindowResize() {
    console.log('handleWindowResize')
    let me = this;
    this.setFarthestPoint()

    this.setState({
      canDrag: false
    });

    setTimeout(function () {
      console.log('RE-ENABLE')
      me.setState({
        canDrag: true
      });
    }, 20)

    this.props.setContainerSize()

  }

  handleTooltipShown(idx) {
    let allButMe = this.dragItemsRef.filter((el, n) => n != idx)
    allButMe.forEach(d => {
      d.hideTooltip()
    });
  }


  get filteredBehaviorMarkers() {

    return this.props.competency.behaviorMarkers.filter(bm => bm.isPositive == (!this.props.isBlocker))
  }

  componentDidMount() {
    setTimeout(this.setContainerSize.bind(this), 200)
    setTimeout(this.setFarthestPoint.bind(this), 200)

  }

  componentDidUpdate(prevProps, prevState) {
    //console.log('componentDidUpdate', prevProps.competency.CompetencyId,this.props.competency.CompetencyId )
    if (prevProps.competency.id !== this.props.competency.id) {
      console.log('componentWillReceiveProps')
      const markerScores = this.createBlankScores()

      this.setState({
        showOverlay: true,
        curItem: 0,
        markerScores
      });
    }
  }

  createBlankScores() {
    return this.filteredBehaviorMarkers.map(b => { return { behaviourMarkerId: b.id, score: null } })
  }

  setContainerSize() {
    const width = this.myContainer.current.offsetWidth
    const height = this.myContainer.current.offsetHeight

    this.props.setViewportSize({ width, height })

  }

  toggleInfoPupoup(){
    this.setState({
      isInfoDialogVisible:!this.state.isInfoDialogVisible
    })
  }

  toggleLeaveAssessmentPupoup(){
    this.setState({
      isLeaveAssessmentDialogVisible:!this.state.isLeaveAssessmentDialogVisible
    })
  }

  leaveAssessment(){
    this.props.history.push(getRoute(ATRAIN_ROUTES.DASHBOARD));
  }


  render() {
    const { t } = i18next
    const feedbackReceiver = this.props.feedbackReceiver;
    const avatarUrl = this.props.avatarUrl;
    const firstName = feedbackReceiver?.firstName || "";
    const lastName = feedbackReceiver?.lastName || "";

    console.log("FeedbackReceiver", feedbackReceiver);
    console.log("AvatarUrl", avatarUrl);

    const draggableItems = this.filteredBehaviorMarkers.map((item, idx) => {
      return <DragItem key={item.id.toString()}
        ref={dragItem => this.dragItemsRef[idx] = dragItem}
        item={item}
        idx={idx}
        constraint={this.myRef}
        containerWidth={this.props.assessmentStore.viewportSize.width}
        dragDisabled={!this.state.canDrag}
        onDrag={this.handleDrag.bind(this, idx)}
        onEnd={this.handleDragEnd.bind(this, idx)}
        onStart={this.handleDragStart.bind(this, idx)}
        onTooltipShown={this.handleTooltipShown.bind(this, idx)}
        onRepositionStart={this.handleRepositionStart.bind(this, idx)}
        curItem={this.state.curItem}
        className="no-select"
        />
    })

    const { avatarRadius, headerHeight, viewportSize } = this.props.assessmentStore
    // const avatarImage = this.props.avatarUrl//avatarImageSelector
    const overlayCircleColor = this.props.isBlocker ? '#e538a0' : '#3397d9'
    const btnNextVisible = (this.state.curItem >= this.filteredBehaviorMarkers.length) ? 'next-visible' : 'next-hidden'

    let hintText = t('assessment.competency_popup.subtitle',{ context: this.props.ownerCtx, ...this.props.tokens })

    const competencyName = this.props.isBlocker ? this.props.competency.negativeName : this.props.competency.positiveName

    const OverlayCircleBottomLabel = t('assessment.drag.bottomlabel', { context: this.props.ownerCtx, ...this.props.tokens });

    return (


      <motion.div
        ref={this.myContainer}
        className='h-100 inner-wrapper no-select'
        animate={{ translateY: 0, opacity: 1 }}
        initial={{ translateY: 20, opacity: 0.5 }}
        transition={{ duration: .35 }}>

        <div className={'competency-item ' + (this.state.isDragging ? 'isDragging' : '') + (this.state.isRepositioning ? ' isRepositioning ' : '')}>
          <div className='behavior-constraint' ref={this.myRef}></div>
          <div className='behavior-step' >

            <BackgroundCircles isBlocker={this.props.isBlocker} />

            <div className="itemsContainer">
              <div className="inner">
                <OverlayCircle
                  circlecolor={overlayCircleColor}
                  distance={this.state.radius}
                  avatarRadius={avatarRadius}
                  headerHeight={headerHeight}
                  viewportSize={viewportSize}
                  onWindowResize={this.handleWindowResize.bind(this)}
                  bottomLabel={OverlayCircleBottomLabel}

                  />

                {draggableItems}

                <div onClick={this.handleBtnNext.bind(this)} className={'btn btn-primary w-100 mt-2 ' + btnNextVisible}>{t('btnNext')}</div>
              </div>
            </div>

            <div className={'avatar'} style={{ backgroundImage: avatarUrl ? `url(${avatarUrl})` : 'none' }}>
              {!avatarUrl && (
                <span className="avatar-initials">
                  {firstName.charAt(0)}
                  {lastName.charAt(0)}
                </span>
              )}
            </div>
            <div className={'drag-hint-text ' + (this.state.showHint ? '' : 'hind-hide')}><IconHand nopadding={true} /> <span  dangerouslySetInnerHTML={{ __html: t('assessment.competency_DD_hint', { competencyname: competencyName }) }}></span></div>
          </div>

        </div>
        <div className="header-icons-wrapper">
          <IconCloseSquare onClick={this.toggleLeaveAssessmentPupoup.bind(this)} />
          {/*farthestPoint:{this.state.farthestPoint}
        */}


          <IconInfoCircle onClick={this.toggleInfoPupoup.bind(this)}/>
        </div>

        <AnimatePresence>
          {this.state.showOverlay && <CompetencyOverlay competencyName={competencyName} isBlocker={this.props.isBlocker} subtitle={hintText} onDismiss={this.handleStartCardDismiss.bind(this)} />}
        </AnimatePresence>


        <AnimatedPopup visible={this.state.isInfoDialogVisible} showCloseButton={true} onCloseButtonClick={this.toggleInfoPupoup.bind(this)}>
          <CardTitle dangerouslySetInnerHTML={{ __html: t('assessment.infopopup.title') }}></CardTitle>
          <CardSubtitle dangerouslySetInnerHTML={{ __html: t('assessment.infopopup.body', { context: this.props.ownerCtx, ...this.props.tokens }) }}></CardSubtitle>
        </AnimatedPopup>


        <AnimatedPopup visible={this.state.isLeaveAssessmentDialogVisible} showCloseButton={false}>
            <CardSubtitle>{t('assessment.leaveAssessment.popup.title')}</CardSubtitle>

            <div>
                <button onClick={this.leaveAssessment.bind(this)} className="minw-25 btn btn-sm btn-outline-primary mr-2">{t('assessment.leaveAssessment.popup.btnConfirm')}</button>

                <button onClick={this.toggleLeaveAssessmentPupoup.bind(this)} className="minw-25 btn btn-sm btn-primary">{t('assessment.leaveAssessment.popup.btnCancel')}</button>
            </div>

        </AnimatedPopup>


      </motion.div>
    );
  }
}




const mapStateToProps = (state) => {

  console.log('state', state)
  return ({
    assessmentStore: state.assessment,
    mainStore: state.main,
    //avatarImageSelector: mainSelectors.avatarImageSelector(state),

  })
};

const mapDispatchToProps = { setViewportSize, setPointerOffset };

const CompetencyItem = connect(mapStateToProps, mapDispatchToProps)(CompetencyItemInner);

export { CompetencyItem }
