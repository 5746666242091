import React, { Component } from 'react';
import IconWrapper from './IconWrapper';

const IconItem = (props) => {
  const color = props.color || '#FFFFFF'
  
  return (
    <IconWrapper {...props} >
	    <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_1027_1618)">
        <path d="M21.0002 19.8597H20.2402C19.4402 19.8597 18.6802 20.1697 18.1202 20.7297L16.4102 22.4197C15.6302 23.1897 14.3603 23.1897 13.5803 22.4197L11.8702 20.7297C11.3102 20.1697 10.5402 19.8597 9.75024 19.8597H9.00024C7.34024 19.8597 6.00024 18.5298 6.00024 16.8898V5.97974C6.00024 4.33974 7.34024 3.00977 9.00024 3.00977H21.0002C22.6602 3.00977 24.0002 4.33974 24.0002 5.97974V16.8898C24.0002 18.5198 22.6602 19.8597 21.0002 19.8597Z" fill={color}/>
        <path d="M21.0002 19.8597H20.2402C19.4402 19.8597 18.6802 20.1697 18.1202 20.7297L16.4102 22.4197C15.6302 23.1897 14.3603 23.1897 13.5803 22.4197L11.8702 20.7297C11.3102 20.1697 10.5402 19.8597 9.75024 19.8597H9.00024C7.34024 19.8597 6.00024 18.5298 6.00024 16.8898V5.97974C6.00024 4.33974 7.34024 3.00977 9.00024 3.00977H21.0002C22.6602 3.00977 24.0002 4.33974 24.0002 5.97974V16.8898C24.0002 18.5198 22.6602 19.8597 21.0002 19.8597Z" stroke="#163345" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <path d="M15.0002 10.9998C16.287 10.9998 17.3302 9.95662 17.3302 8.6698C17.3302 7.38298 16.287 6.33984 15.0002 6.33984C13.7134 6.33984 12.6702 7.38298 12.6702 8.6698C12.6702 9.95662 13.7134 10.9998 15.0002 10.9998Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.0002 16.6594C19.0002 14.8594 17.2102 13.3994 15.0002 13.3994C12.7902 13.3994 11.0002 14.8594 11.0002 16.6594" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
        <filter id="filter0_d_1027_1618" x="0.500244" y="0.509766" width="29" height="30.9873" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="3"/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0880208 0 0 0 0 0.198149 0 0 0 0 0.270833 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1027_1618"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1027_1618" result="shape"/>
        </filter>
        </defs>
      </svg>

    </IconWrapper>
  )
}

export default IconItem