import React, { Component } from 'react';
import { Container, Navbar } from 'reactstrap';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import { t } from 'i18next';
import { ATRAIN_ROUTES, getRoute } from 'routes';
import accountService from 'api/accountService';
import { storeActions } from 'stores/storeHelpers'

const defaultAvatarUrl = '/img/profile-circle.svg'

export class DashboardMenu extends Component {

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();

    this.state = {
      isMenuOpen: false
    }

    this.handleClickOutside = this.handleClickOutside.bind(this);


  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        isMenuOpen: false
      })
    }
  }

  toggle() {
    this.setState({
        isMenuOpen: !this.state.isMenuOpen
    })
  }

  logout() {
    accountService.logout().then(()=>{
      window.location.href = ATRAIN_ROUTES.LOGIN
    })
    .catch(err =>{
      storeActions.setErrorMessage(true,err.message)
    })
  }

  render() {
    const fullName = this.props.user.firstName + ' ' + this.props.user.lastName
    const avatarUrl = this.props.user.avatarUrl || defaultAvatarUrl
    const initials = (this.props.user.firstName.charAt(0) || '') + (this.props.user.lastName.charAt(0) || '');

    const subMenuAnimate = {
      enter: {
        opacity: 1,
        translateY: 0,
        transition: {
          duration: 0.15
        },
        display: "block"
      },
      exit: {
        opacity: 0,
        translateY: -15,
        transition: {
          duration: 0.15,
          delay: 0
        },
        transitionEnd: {
          display: "none"
        }
      }
    };

    return (
      <header>
        <Navbar className="feature navbar dashboard-navbar">
          <Container>
            <div className='avatarinfo custom-dropdown' ref={this.wrapperRef}>

              <ul className="list-inline meta mb-0 align-items-center d-flex text-left" onClick={this.toggle.bind(this)} >
                <li className="list-inline-item">
                  {avatarUrl ? (
                  <img src={avatarUrl} alt="" />
                  ) : (
                  <div className="avatar-initials"
                    style={{ backgroundColor: '#F5F5F5', borderRadius: '50%', width: '40px', height: '40px', lineHeight: '40px', textAlign: 'center', color: '#000' }}
                  >
                    {initials}
                  </div>
                )}
                </li>
                <li className="list-inline-item">
                  <div className=' header-avatar-text'><big>Welcome!</big></div>

                  {fullName}
                </li>
              </ul>

              <motion.div
                className='custom-dropdown-items-animate'
                animate={this.state.isMenuOpen ? "enter" : "exit"}
                variants={subMenuAnimate}
                initial="exit">
                <div className="custom-dropdown-items">

                  <div className='custom-dropdown-item'><Link to={getRoute(ATRAIN_ROUTES.SET_AVATAR,{isregistration:''})}>{t('dashboard.menu.setAvatar')}</Link></div>
                  <div className='custom-dropdown-item' onClick={this.logout.bind(this)} >{t('dashboard.menu.logout')}</div>
                </div>
              </motion.div>

            </div>
            <div>{this.props.children}</div>
          </Container>
        </Navbar>
      </header>
    );
  }
}
