import React, { Component } from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import {Header} from 'components/Layout/Header'
import SponsorInvitation from 'components/SponsorInvitation';
import {getSponsors, sendSponsorInvitation} from 'api/index';
import {ATRAIN_ROUTES, getRoute} from 'routes'
export class InviteSponsorPage extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loaded:false,
      users:[]
    }
  }

  componentDidMount(){
    getSponsors().then(res => {

      this.setState({
          users:res.data,
          loaded:true
      })

  })
  }

  handleSendSponsorInvitation(selectedUser) {
      let data = {
        assessmentId:this.props.match.params.assessmentId,
        user:selectedUser
      }

      console.log('handleSendSponsorInvitation', data)
      sendSponsorInvitation(data).then(resp => {
          this.goToDashboard()
      })
  }

  goToDashboard(){
    let history = this.props.history;
    history.push(ATRAIN_ROUTES.DASHBOARD)
  }

  render() {
    const {loaded, users} = this.state

    return (
      
      <>
      {loaded && <div className={'invite-sponsor'}>
   
        <Header
          className={'blocker'}
          closeLink={getRoute(ATRAIN_ROUTES.DASHBOARD)}
          >

        </Header>
        
        <Card className={'card-element rounded-big mb-0'} >
          <CardBody className=''>
           
            <SponsorInvitation users={users} onSend={this.handleSendSponsorInvitation.bind(this)} />
            
            <div className="text-center mt-3 mb-1">
                <strong className="d-block text-muted">- OR -</strong>
                <a href="#" className="skipstep" onClick={(e) => {this.goToDashboard(); e.preventDefault()}}>continue without sponsor</a>
            </div>

          </CardBody>
        </Card>

      </div>

      }
      </>
    );
  }
}




/*
const mapStateToProps = (state) => {
  return ({
    mainStore: state.main
})};

const mapDispatchToProps = {setUser};

const DashboardPage = connect(mapStateToProps, mapDispatchToProps)(DashboardPageInner);
*/
