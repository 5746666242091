import React, { Component } from 'react';
import IconWrapper from './IconWrapper';

const IconItem = (props) => {
  const color = props.color || '#FFFFFF'
  
  return (
    <IconWrapper {...props} >
	    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.75732 16.7427L16.2426 8.25739" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.2426 16.7426L7.75732 8.25732" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </IconWrapper>
  )
}

export default IconItem
