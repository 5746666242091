import { HTTP } from 'api'
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      foo: "Foo",
    },
  }
};

export const loadTranslations = (lang, cb) =>{
//  HTTP.get('../../mock/translations.json')
HTTP.get('/Globalization')
  .then((response) => {
      resources[lang] = {translation:response.data}
        
      i18next
      .use(initReactI18next)
      .init({
        resources,
        lng: lang,
        interpolation: {
          escapeValue: false,
        },
      });


      cb()
    })
  .catch((error) => { console.log(error); });

}


export function fullDate(d){

  let lang = 'en-US';

  switch (i18next.language) {
    case 'en':
      lang = 'en-US'
      break;
    default:
      lang = i18next.language + '-' + i18next.language.toLocaleUpperCase()
      break;
  }

  var options = {
    year: 'numeric', month: '2-digit', day: '2-digit',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false
  }

  return new Intl.DateTimeFormat(lang, options).format(d)
}