import { HTTP } from 'api'

//const basePath = "Account/";
//const basePath = "../../mock/";
const basePath = "/dashboard";

export default {

  /*getDashboardData(projectId) {
    console.log(projectId)
    return HTTP.get(basePath.concat('dashboard_' + projectId + '.json?proj=' + projectId))
  }*/

    getDashboardData(projectId) {
    return HTTP.get(basePath + '?projectId=' + projectId)
  }
  
}