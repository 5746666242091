import React, { Component } from 'react';

import { IconCross } from 'components/icons'

const defaultAvatarUrl = '/img/profile-circle.svg'

export default class ContactTag extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const avatarUrl = this.props.avatarUrl || defaultAvatarUrl

        return (
            <div className='contact-tag'>
                <div>
                <img src={avatarUrl}/>

                <span className='tag-name'>{this.props.email}</span>
                <span className='btn-remove-contact' onClick={this.props.onRemove}>
                    <IconCross nopadding={true} color="#ffa61e" />
                </span>
                </div>
            </div>
        );
    }
}


