import { configureStore } from '@reduxjs/toolkit';
import assessmentReducer from './assessmentSlice';
import mainReducer from './mainSlice';

export const store = configureStore({
  reducer: {
    assessment: assessmentReducer,
    main: mainReducer,
  },
});
