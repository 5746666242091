import React, { Component } from 'react';
import { CardSubtitle, CardBody } from 'reactstrap';
import AnimatedPopup from 'components/AnimatedPopup';
import { motion } from "framer-motion"
import { IconMore } from 'components/icons'
import { t } from 'i18next';
import assessmentService from 'api/assessmentService';
import {storeActions} from 'stores/storeHelpers'
import { developmentLoopService } from 'api/developmentLoopService';

export default class DropDownAssessmentMenu extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();

        this.state = {
            isMenuOpen: false,
            isCloseAssessmentDialogVisible:false,
            errorMessage:'',
            errorMessageVisible:false
        }

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                isMenuOpen: false
            })
        }
    }

    toggle() {
        this.setState({
            isMenuOpen: !this.state.isMenuOpen
        })
    }


    closeAssessment(){
        console.log('closeAssessment')

        storeActions.setIsLoading(true)
        assessmentService.closeAssessment(this.props.assessmentId).then(res => {
            this.setState({
                isCloseAssessmentDialogVisible:false
            })

            setTimeout(()=>{
                window.location.reload()
            },1000)
        }).catch((err)=>{
            let errorMessage = 'Generic Error'

            try {
                errorMessage = err.response.data
            } catch (error) {
                
            }

            this.setState({
                errorMessage: t(errorMessage),
                errorMessageVisible:true
            })

        })
        .finally(() => {
            storeActions.setIsLoading(false)
        })
    
    }

    openAssessment(){
        console.log('openAssessment')

        /*storeActions.setIsLoading(true)
        assessmentService.close(this.props.assessmentId).then(res => {
            this.setState({
                isCloseAssessmentDialogVisible:false
            })
        }).catch(()=>{})
        .finally(() => {
            storeActions.setIsLoading(false)
        })*/
    
    }

    
    toggleCloseAssessmentPupoup(){
        this.setState({
            isCloseAssessmentDialogVisible:!this.state.isCloseAssessmentDialogVisible,
            isMenuOpen:false
        })
    }


    render() {
        const subMenuAnimate = {
            enter: {
                opacity: 1,
                translateY: 0,
                transition: {
                    duration: 0.15
                },
                display: "block"
            },
            exit: {
                opacity: 0,
                translateY: -15,
                transition: {
                    duration: 0.15,
                    delay: 0
                },
                transitionEnd: {
                    display: "none"
                }
            }
        };
        const assessmentIsOpen = this.props.open
        return (

            <div className='custom-dropdown' ref={this.wrapperRef}>
                <div onClick={this.toggle.bind(this)} className={"custom-dropdown-handle " + (this.state.isMenuOpen ? 'is-open' : '')}>
                    <IconMore color={this.state.isMenuOpen ? "#ffa61e" : "#fff"} />
                </div>

                {assessmentIsOpen && <motion.div
                    className='custom-dropdown-items-animate'
                    animate={this.state.isMenuOpen ? "enter" : "exit"}
                    variants={subMenuAnimate}
                    initial="exit">
                    <div className="custom-dropdown-items">
                        {assessmentIsOpen && <div className='custom-dropdown-item' onClick={this.toggleCloseAssessmentPupoup.bind(this)}>{t('btnCloseAssessment')}</div>}
                        {/*!assessmentIsOpen && <div className='custom-dropdown-item' onClick={this.openAssessment.bind(this)}>{t('btnOpenAssessment')}</div>*/}
                    </div>
                </motion.div>}



                

                <AnimatedPopup visible={this.state.isCloseAssessmentDialogVisible} showCloseButton={false}>
                            <CardSubtitle>{t('report.closeAssessment.popup.title')}</CardSubtitle>

                            <div>
                                <button onClick={this.closeAssessment.bind(this)} className="btn btn-sm btn-outline-primary mr-2">{t('report.closeAssessment.popup.btnConfirm')}</button>
                                
                                <button onClick={this.toggleCloseAssessmentPupoup.bind(this)} className="btn btn-sm btn-primary">{t('report.closeAssessment.popup.btnCancel')}</button>
                            </div>

                            {this.state.errorMessageVisible && <div className='mt-2 text-danger'>{this.state.errorMessage}</div>}

                        </AnimatedPopup>

            </div>

        );
    }
}




