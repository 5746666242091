import React, { Component } from 'react';

import {Badge
} from 'reactstrap';

export default class BadgeDate extends Component {
  render() {
    return (
        <Badge color="light" pill className='custom-badge date-badge dimmed'>{this.props.date}</Badge>
    );
  }
}



            
