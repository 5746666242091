import React, { Component } from 'react';
import i18next from "i18next";

import {Badge
} from 'reactstrap';

export default class BadgeOpenClose extends Component {
    render() {
        const { t } = i18next
    //const dateBadge = <Badge color="light" pill className='dimmed'>{this.props.date}</Badge>
    return (
        this.props.open ? <Badge color="light" pill className='custom-badge text-uppercase'>{this.props.label || t('generic.status.open')}</Badge> : <Badge color="light" pill className='custom-badge dimmed text-uppercase'>{this.props.label || t('generic.status.closed')}</Badge>
    );
  }
}