import { HTTP } from 'api'

const basePath = '/profile/'

export default {

  UploadAvatar(file){
    //return HTTP.post(basePath.concat('UploadPhoto'));

    const formData = new FormData();
    formData.append('Avatar', file, 'resizedfile.png')

    for (let value of formData.values()) {
      console.log(value);
   }

    //return HTTP.post('Profile_UploadPhoto.json', formData)
    return HTTP.post(basePath.concat('UploadAvatar'),formData,{
      headers: { "Content-Type": "multipart/form-data" },
    });
    //return HTTP.get('Profile_UploadPhoto.json', JSON.stringify(formData))


    /*const url = `http://target-url/api/${this.state.id}`;
    const formData = new FormData();
    formData.append('body', this.state.file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return post(url, formData, config);*/

    
    
  },

  getUserInfo() {
    return HTTP.get(basePath);
    //return HTTP.get('userinfo.json')
  }

  
}