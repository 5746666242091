import React, { Component } from 'react';
import IconWrapper from './IconWrapper';

const IconItem = (props) => {
  const color = props.color || '#FFFFFF'
  
  return (
    <IconWrapper {...props} >
	    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 7.875V11.625C17 14.25 15.5 15.375 13.25 15.375H5.75C3.5 15.375 2 14.25 2 11.625V6.375C2 3.75 3.5 2.625 5.75 2.625H11" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.75 6.75L8.0975 8.625C8.87 9.24 10.1375 9.24 10.91 8.625L11.795 7.92" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.125 6C16.1605 6 17 5.16053 17 4.125C17 3.08947 16.1605 2.25 15.125 2.25C14.0895 2.25 13.25 3.08947 13.25 4.125C13.25 5.16053 14.0895 6 15.125 6Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>

    </IconWrapper>
  )
}

export default IconItem