import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from 'reactstrap';
import { motion } from "framer-motion"
import { IconArrowSquareDown } from 'components/icons'

export default class DropDownProjectsMenu extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();

        this.state = {
            isMenuOpen: false
        }

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                isMenuOpen: false
            })
        }
    }

    toggle() {
        this.setState({
            isMenuOpen: !this.state.isMenuOpen
        })
    }

    close(){
        this.setState({
            isMenuOpen: false
        })
    }

    onItemClick(project){
        this.close()
        this.props.onItemClick(project)
    }

    render() {
        const subMenuAnimate = {
            enter: {
                opacity: 1,
                translateY: 0,
                transition: {
                    duration: 0.15
                },
                display: "block"
            },
            exit: {
                opacity: 0,
                translateY: -15,
                transition: {
                    duration: 0.15,
                    delay: 0
                },
                transitionEnd: {
                    display: "none"
                }
            }
        };

        return (

            <div className='custom-dropdown project-dropdown' ref={this.wrapperRef}>
                <div onClick={this.toggle.bind(this)} className={"custom-dropdown-handle " + (this.state.isMenuOpen ? 'is-open' : '')}>
                <span>{this.props.selected.name}</span> <IconArrowSquareDown color={this.state.isMenuOpen ? "#000" : "#000"} />
                </div>

                <motion.div
                    className='custom-dropdown-items-animate'
                    animate={this.state.isMenuOpen ? "enter" : "exit"}
                    variants={subMenuAnimate}
                    initial="exit">
                    <div className="custom-dropdown-items">

                        {this.props.projects.map(project =>{
                            return <div className='custom-dropdown-item' key={project.id} onClick={() => this.onItemClick(project)}>{project.name}</div>  
                        })}

                    </div>
                </motion.div>

            </div>

        );
    }
}




