import React, { Component } from 'react';
import {HalfCardLayout} from 'components/Layout/HalfCardLayout'
import { ForgotPassword } from 'components/UserCreate/ForgotPassword';
import i18next from "i18next";
import {ATRAIN_ROUTES} from 'routes'


export class ForgotPasswordPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //mailSent:false,
      invitationToken:null,
      isPageVisible: true,
      step:'',
      user:{
        firstName:''
      }
    }
  }

  handleNext(o){
    console.log('handleNext 0')

  }

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  

  componentDidMount(){

  }
   
  render() {
    const {isPageVisible, mailSent, user, step, invitationToken} = this.state
    const {t} = i18next

    return (
     <div className='setavatar-wrapper' >
        {isPageVisible && 
        <HalfCardLayout
          halfheight={true}
          bgimage="/img/card-bg.jpg"
          bgtitle={t('login.claimTitle')}
        >
        
            <ForgotPassword onDone={this.handleNext.bind(this)}/>
            

        </HalfCardLayout>
      }
      </div>
    );
  }
}