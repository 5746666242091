
import {store} from 'stores/store'
import { setUser, setCurrentProject, setIsLoading, setErrorMessage } from "stores/mainSlice";

let spinnerTimeout;

export const storeActions = {
  setIsLoading: (visible)=>{

    if(visible){
        spinnerTimeout = setTimeout(() =>{store.dispatch(setIsLoading(visible))}, 500)
    }else{
        clearTimeout(spinnerTimeout)
        store.dispatch(setIsLoading(visible))
    }

    
  },

  setErrorMessage:(visible, text) =>{
    store.dispatch(setErrorMessage({visible, text}))
  }
}

export const storeState = store.getState()
  