import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Container, Card, CardBody, CardSubtitle } from 'reactstrap';
import {Header} from 'components/Layout/Header'

import {ATRAIN_ROUTES, getRoute} from 'routes'
import AnimatedPopup from 'components/AnimatedPopup';
import i18next from "i18next";
import mainService from 'api/mainService';
import { store } from 'stores/store';
import { IMPROVEMENTS_RANKING_TYPES } from 'types';
import developmentLoopService from 'api/developmentLoopService';
import FollowUpSuggest from 'components/FollowUpSuggest';

export class FollowUpPage extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loaded:true,
      feedbackPersons:[],
      isDialogVisible:false,
      devloopData:{}
    }
  }

  componentDidMount() {
        
    developmentLoopService.getDevloop(this.props.match.params.developmentLoopId).then(res => {
        this.setState({
            devloopData: res.data
        });
    })
}

  handleSendRatersInvitation(feedbackPersons, selectedMarker, message) {
      //let history = this.props.history;
      let behaviorMarkerId = selectedMarker || this.props.match.params.behaviorMarkerId
      let developmentLoopId = this.props.match.params.developmentLoopId

      let data = {
        behaviorMarkerId:behaviorMarkerId,
        feedbackPersons,
        message
      }

      developmentLoopService.followUp(developmentLoopId, data).then(resp => {
        this.setState({
          isDialogVisible: true
        })
      }).catch(err =>{
        alert(i18next.t('networkError'))
        console.log('err', err)
      })
  }

  toggle(){
    console.log('toggle', this.state.isDialogVisible, mainService)
    this.setState({
      isDialogVisible: !this.state.isDialogVisible
    })
  }

  render() {
    const {loaded} = this.state
    const { t } = i18next

    const { devloopData: {markers} } = this.state

    let listType = 'enablers'
    
    /*switch (this.props.match.params.toptype) {
      case IMPROVEMENTS_RANKING_TYPES.TopBlockers:
        listType = 'blocker'
        break;
      case IMPROVEMENTS_RANKING_TYPES.BottomEnablers:
        listType = 'enabler'
          break;
      case IMPROVEMENTS_RANKING_TYPES.BiggestGap:
        listType = 'gap'
        break;

      default:
        listType = 'all'
        break;
    }*/

//bgcolor="#3397D9"
    return (
      
      <>
      {loaded && <div className={'invite-rater'}>
   
        <Header
          className={listType}
          closeLink={getRoute(ATRAIN_ROUTES.DEVLOOP, { developmentLoopId: this.props.match.params.developmentLoopId})}
          >

        </Header>
        
        <Card className={'card-element rounded-big'} >
          <CardBody className=''>
           
            {markers && <FollowUpSuggest onSend={this.handleSendRatersInvitation.bind(this)} markers={markers} developmentLoopId={this.props.match.params.developmentLoopId} defaultMarker={this.props.match.params.behaviorMarkerId > 0 ? this.props.match.params.behaviorMarkerId : markers[0].behaviorMarkerId}/>}
            
          </CardBody>
        </Card>

        <AnimatedPopup visible={this.state.isDialogVisible}>
          <CardSubtitle dangerouslySetInnerHTML={{ __html: t('devloop.followup.gotodashboard_text') }}></CardSubtitle>
          <Link to={getRoute(ATRAIN_ROUTES.DASHBOARD)}><button className="btn btn-sm w-100 btn-primary mb-3" dangerouslySetInnerHTML={{ __html: t('devloop.followup.gotodashboard_btn') }}></button></Link>
        </AnimatedPopup>

      </div>

      }
      </>
    );
  }
}

