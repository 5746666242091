import React, { Component } from 'react';
import { connect } from "react-redux";
import {HalfCardLayout} from 'components/Layout/HalfCardLayout'
import { SetAvatar } from 'components/UserCreate/SetAvatar';
import { setUser } from "stores/mainSlice";
import { ATRAIN_ROUTES } from 'routes';
import i18next from "i18next";

class SetAvatarPageInner extends Component {

  constructor(props) {
    super(props);
    this.state = {
      step:'',
      user:{
        firstName:'',
        lastName:''
      }
    }
  }

  handleNext(data){
    console.log('data', data)
    if(data.setAvatar){
      this.props.setUser({...this.props.mainStore.user, avatarUrl: data.avatarUrl })
    }
    this.props.history.push(ATRAIN_ROUTES.DASHBOARD);
  }

  componentDidMount(){
   
  }
   
  
  render() {
    const isregistration = this.props.match.params.isregistration
    const {t} = i18next
    const bgtitle = t('claiminvitation.title')
    const bgtext = (isregistration) ? t('claiminvitation.subtitle',{firstName:this.props.mainStore.user.firstName}) : t('changeavatar.subtitle',{firstName:this.props.mainStore.user.firstName})

    return (
  
     <div className='setavatar-wrapper' >
        {this.props.mainStore.user && 
        <HalfCardLayout
          halfheight={true}
          bgimage="/img/card-bg.jpg"
          bgtitle={bgtitle}
          bgtext={bgtext}
        >
      
            <SetAvatar isregistration={isregistration} user={this.props.mainStore.user} onNext={this.handleNext.bind(this)} />

        </HalfCardLayout>
      }
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  
  //console.log('state', state)
  return ({
    //assessmentStore: state.assessment,
    mainStore: state.main,
    //avatarImageSelector:mainSelectors.avatarImageSelector(state),
  
})};

const mapDispatchToProps = {setUser};

const SetAvatarPage = connect(mapStateToProps, mapDispatchToProps)(SetAvatarPageInner);

export {SetAvatarPage}
