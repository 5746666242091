import React, { Component } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import {Header} from 'components/Layout/Header'
import invitationService from 'api/invitationService';
import i18next from "i18next";
import { IconSmsNotification } from 'components/icons'
import { Link } from 'react-router-dom';


import {ATRAIN_ROUTES, getRoute} from 'routes'
export class InviteMoreRatersPage extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loaded:false,
      users:[]
    }
  }

  componentDidMount(){
    invitationService.raterList(this.props.match.params.assessmentId).then(res => {

      this.setState({
          users:res.data,
          loaded:true
      })

  })
  }

  handleSendInvitation() {
    const assessmentId = this.props.match.params.assessmentId
    this.props.history.push(getRoute(ATRAIN_ROUTES.INVITE_RATER,{assessmentId}));
  }

  render() {
    const {loaded, users} = this.state
    const { t } = i18next
    const assessmentId = this.props.match.params.assessmentId
    
    return (
      
      <>
      {loaded && <div className={'invite-sponsor'}>
   
        <Header
          className={'blocker'}
          closeLink={getRoute(ATRAIN_ROUTES.REPORT,{assessmentId})}
          >

        </Header>
        
        <Card className={'card-element rounded-big mb-0'} >
          <CardBody className=''>

            <div>

                    <b className='sponsor-intro-text'>{t('invitemoreraters.intro')}</b>
                    <div className='sponsor-list'>
                   {
                       users.map( u => {

                        return (
                            <div key={u.userId} className={'sponsor-tag ' }>
                              <img className="mr-0" src="/img/profile-circle.svg"/>
                              

                                <span className='tag-name ml-0'>{u.email}</span>
                            </div>
                        )

                       })
                   }
                    </div>
                    
                    <Button className='btn-icon btn-primary w-100 btn-send' onClick={this.handleSendInvitation.bind(this)}><IconSmsNotification nopadding={true} displayblock={true} />{t('report.btnAddRater')}</Button>

                </div>

          </CardBody>
        </Card>

      </div>

      }
      </>
    );
  }
}




/*
const mapStateToProps = (state) => {
  return ({
    mainStore: state.main
})};

const mapDispatchToProps = {setUser};

const DashboardPage = connect(mapStateToProps, mapDispatchToProps)(DashboardPageInner);
*/
