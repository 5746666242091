import React, { Component } from 'react';
import {motion} from "framer-motion"

export class SlideInOut extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {className} = this.props
    const motionInitial = {translateY: 20, opacity:0,  position: 'absolute', top: 0,right: 0, left: 0,bottom: 0, }
    const motionExit = {translateY: -20, opacity:0, position: 'absolute', top: 0,right: 0, left: 0,bottom: 0,}
    const motionAnimate = { translateY: 0, opacity:1 }

    return (
        <motion.div
            className={'animatedPopup ' + className}
            animate={motionAnimate}
            initial={motionInitial}
            exit={motionExit}
            transition={{ duration: .35 }}>

                 {this.props.children && this.props.children}

        </motion.div>
    );
  }
}
