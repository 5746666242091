import React, { Component } from 'react'
import { AnimatePresence } from "framer-motion"
import { SlideInOut } from 'components/Layout/SlideInOut'
import { SimplePopup } from 'components/Layout/SimplePopup'
import i18next from "i18next";

export default class AnimatedPopup extends Component {

  onCloseButtonClick() {
    if(typeof this.props.onCloseButtonClick == 'function'){
      this.props.onCloseButtonClick()
    }
  }

  onOverlayClick(e) {
    if(e.target !== e.currentTarget) return;
    if(typeof this.props.onCloseButtonClick == 'function'){
      this.props.onCloseButtonClick()
    }
  }

  

  render() {
    const { t } = i18next

    return (
      <AnimatePresence>
      {this.props.visible &&
        <SlideInOut className='h-100'>
          <div className='popup-overlay' onClick={this.onOverlayClick.bind(this)}>
            <SimplePopup className="text-center"
              onClose={this.onCloseButtonClick.bind(this)}
              showCloseButton={this.props.showCloseButton || false}
            >

            {this.props.children}

            </SimplePopup>
          </div>
        </SlideInOut>
      }
    </AnimatePresence>
    )
  }
}

