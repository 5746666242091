import React, { Component } from 'react';
import IconWrapper from './IconWrapper';

const IconItem = (props) => {
  const color = props.color || '#FFFFFF'
  
  return (
    <IconWrapper {...props} >
	    <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_1027_1626)">
        <path d="M21.0002 19.8597H20.2402C19.4402 19.8597 18.6802 20.1697 18.1202 20.7297L16.4102 22.4197C15.6302 23.1897 14.3603 23.1897 13.5803 22.4197L11.8702 20.7297C11.3102 20.1697 10.5402 19.8597 9.75024 19.8597H9.00024C7.34024 19.8597 6.00024 18.5298 6.00024 16.8898V5.97974C6.00024 4.33974 7.34024 3.00977 9.00024 3.00977H21.0002C22.6602 3.00977 24.0002 4.33974 24.0002 5.97974V16.8898C24.0002 18.5198 22.6602 19.8597 21.0002 19.8597Z" fill={color}/>
        <path d="M21.0002 19.8597H20.2402C19.4402 19.8597 18.6802 20.1697 18.1202 20.7297L16.4102 22.4197C15.6302 23.1897 14.3603 23.1897 13.5803 22.4197L11.8702 20.7297C11.3102 20.1697 10.5402 19.8597 9.75024 19.8597H9.00024C7.34024 19.8597 6.00024 18.5298 6.00024 16.8898V5.97974C6.00024 4.33974 7.34024 3.00977 9.00024 3.00977H21.0002C22.6602 3.00977 24.0002 4.33974 24.0002 5.97974V16.8898C24.0002 18.5198 22.6602 19.8597 21.0002 19.8597Z" stroke="#163345" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <path d="M13.2402 14.6752L14.7902 15.8752C14.9902 16.0752 15.4402 16.1752 15.7402 16.1752H17.6402C18.2402 16.1752 18.8902 15.7252 19.0402 15.1252L20.2402 11.4752C20.4902 10.7752 20.0402 10.1752 19.2902 10.1752H17.2902C16.9902 10.1752 16.7402 9.92523 16.7902 9.57523L17.0402 7.97523C17.1402 7.52523 16.8402 7.02523 16.3902 6.87523C15.9902 6.72523 15.4902 6.92523 15.2902 7.22523L13.2402 10.2752" stroke="white" strokeWidth="1.5" strokeMiterlimit="10"/>
        <path d="M10.6904 14.6752V9.7752C10.6904 9.0752 10.9904 8.8252 11.6904 8.8252H12.1904C12.8904 8.8252 13.1904 9.0752 13.1904 9.7752V14.6752C13.1904 15.3752 12.8904 15.6252 12.1904 15.6252H11.6904C10.9904 15.6252 10.6904 15.3752 10.6904 14.6752Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
        <filter id="filter0_d_1027_1626" x="0.500244" y="0.509766" width="29" height="30.9873" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="3"/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0880208 0 0 0 0 0.198149 0 0 0 0 0.270833 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1027_1626"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1027_1626" result="shape"/>
        </filter>
        </defs>
      </svg>


    </IconWrapper>
  )
}

export default IconItem