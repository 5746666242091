import { HTTP } from 'api'

const basePath = "/AssessmentSession/";

export default {

  getAssessmentSession(assessmentId){
    return HTTP.get(basePath.concat(assessmentId))
  },

  saveCompetencyStep(scores, assessmentSessionId){
    return HTTP.patch(basePath.concat(assessmentSessionId), {scores})
  }
}