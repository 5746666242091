import React, { Component } from 'react';

import { Label, Input, Button} from 'reactstrap';
import i18next from "i18next";
import accountService from 'api/accountService'
import {isValidEmail } from 'utils';
import {storeActions} from 'stores/storeHelpers'

export class ForgotPassword extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      password:'',
      username:'',
      errorMessageVisible:false,
      errorMessage:' ',
      mailSent:false,
    }
    this.onInputchange = this.onInputchange.bind(this);
  }

  onSendData(event){
    event.preventDefault();

    
    storeActions.setIsLoading(true)

    accountService.forgotPassword(
      this.state.username
        )
        .then( resp => {

          this.setState({mailSent:true})
          this.props.onDone()
        }).catch(err => {
          let errorMessage= ''

          try {
            errorMessage = err.response.data
          } catch (error) {
            errorMessage = 'Generic error'
          }

          this.setState({
            errorMessage,
            errorMessageVisible:true
          })

        }).finally(()=>storeActions.setIsLoading(false))
    
  }

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  
    
  get isFormFilled(){
    let valid = this.state.username != '' && isValidEmail(this.state.username)
    return valid
  }

  render() {
    //const {users, competency, totalReplies} = this.state.reportData
    const {t} = i18next
    const {mailSent} = this.state
    return (
      <div>
          <h3 className="mt-4 pb-2 text-capitalize h4 ">{t('forgotpassword.cardTitle')}</h3>

          {mailSent && <div>{t('forgotpassword.mailsent')}</div>}
          {!mailSent && <><div className="pb-2">{t('forgotpassword.cardText')}</div>
          
          <form className="mt-4 mb-2" onSubmit={this.onSendData.bind(this)}>
						<div className="form-group">
							<Label for="username">{t('forgotpassword.lblUsername')}</Label>
							<Input
                  name="username"
                  placeholder=""
                  type="email"
                  className='form-control main'

                  value={this.state.username}
                  onChange={this.onInputchange}
                />
						</div>
            <div className={'fieldErrorMessage text-danger text-center mb-3 ' + (this.state.errorMessageVisible ? 'd-shown':'d-hidden')}>{this.state.errorMessage}</div>
            <Button disabled={!this.isFormFilled} type="submit" className="btn btn-primary w-100">{t('forgotpassword.btnSendMail')}</Button>
					</form>
          </>}
      </div>
    );
  }
}

//https://localhost:44492/account