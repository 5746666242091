import React, { Component } from 'react';
import IconWrapper from './IconWrapper';

const IconItem = (props) => {
  const color = props.color || '#FFFFFF'
  
  return (
    <IconWrapper {...props} >
	    <svg width="20" height="25" viewBox="5 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_1027_2288)">
        <path d="M16.8814 16.6732H17.4444C18.674 16.6732 19.6666 15.6129 19.6666 14.323V5.68997C19.6666 4.39225 18.674 3.33984 17.4444 3.33984H8.55547C7.32584 3.33984 6.33325 4.39225 6.33325 5.68997V14.323C6.33325 15.6208 7.32584 16.6732 8.55547 16.6732H9.11103H16.8814Z" fill={color}/>
        <path d="M16.8814 16.6732H17.4444C18.674 16.6732 19.6666 15.6129 19.6666 14.323V5.68997C19.6666 4.39225 18.674 3.33984 17.4444 3.33984H8.55547C7.32584 3.33984 6.33325 4.39225 6.33325 5.68997V14.323C6.33325 15.6208 7.32584 16.6732 8.55547 16.6732H9.11103H16.8814Z" stroke="#163345" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.9999 7.16699C12.4181 7.16699 11.9465 7.63857 11.9465 8.2203C11.9465 8.80204 12.4181 9.27364 12.9999 9.27364C13.5816 9.27364 14.0532 8.80204 14.0532 8.2203C14.0532 7.63857 13.5816 7.16699 12.9999 7.16699ZM10.9465 8.2203C10.9465 7.08625 11.8659 6.16699 12.9999 6.16699C14.1339 6.16699 15.0532 7.08625 15.0532 8.2203C15.0532 9.35432 14.1339 10.2736 12.9999 10.2736C11.8659 10.2736 10.9465 9.35432 10.9465 8.2203Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.83325 13.5473C9.83325 11.9805 11.3508 10.874 12.9999 10.874C14.649 10.874 16.1666 11.9805 16.1666 13.5473C16.1666 13.8235 15.9427 14.0473 15.6666 14.0473C15.3904 14.0473 15.1666 13.8235 15.1666 13.5473C15.1666 12.7141 14.2975 11.874 12.9999 11.874C11.7024 11.874 10.8333 12.7141 10.8333 13.5473C10.8333 13.8235 10.6094 14.0473 10.3333 14.0473C10.0571 14.0473 9.83325 13.8235 9.83325 13.5473Z" fill="white"/>
        <defs>
        <filter id="filter0_d_1027_2288" x="0.833252" y="0.839844" width="24.3333" height="24.333" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="3"/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0880208 0 0 0 0 0.198149 0 0 0 0 0.270833 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1027_2288"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1027_2288" result="shape"/>
        </filter>
        </defs>
      </svg>
    </IconWrapper>
  )
}

export default IconItem