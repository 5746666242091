import React, { Component } from 'react'

export default class Avatar extends Component {

  render() {
    let {user, avatarUrl, shortName, size, className} = this.props
    className = className || ''
    avatarUrl = user.avatarUrl || avatarUrl
    shortName = user ? user.firstName[0] + user.lastName[0] : shortName || ''

    return (
        <div className={className + ' avatar unset ' + (size == 'small' ? 'avatar-small' : '')} style={avatarUrl && { backgroundImage: 'url(' + avatarUrl + ')' }}>
        {!avatarUrl && <span>{shortName}</span>}
        </div>
    )
  }
}

