export function isValidEmail(email){
    let emailRegExp = new RegExp('^(.+)@(.+)\\.(.+)$')
    return emailRegExp.test(email)
}

export function getQueryParam(param){
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      
      return params[param];
}


export function setPrjFromQs(){
    const qsPrj = getQueryParam('prj')

    if(qsPrj){
      if(!isNaN(qsPrj)){
        sessionStorage.setItem('currentproject', qsPrj)
      }
    }
}

export const td = (dt) => {
    const options = {  year: '2-digit', month: 'short', day: 'numeric' };
    const date  = new Date(dt);
    const lang = sessionStorage.getItem('__appLang')
    return date.toLocaleDateString(lang, options)
  }


export const stripHtml = (html) => {
 /* const regex = /(<([^>]+)>)/ig;
const r = html.replace(regex, '').replaceAll('&nbsp;',' ').replaceAll('&#39;',' ')
console.log('stripped',r, html);
*/

let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";


  //return  tmp 
}



export const dataURLToBlob = (dataURL) => {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = parts[1];

        return new Blob([raw], {type: contentType});
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    let blob = new Blob([uInt8Array] ,{type: contentType});
    blob.name ='file.png'
    return blob
}