import React from 'react';
import IconWrapper from './IconWrapper';

const IconItem = (props) => {
  const color = props.color || '#3397D9'
  
  return (
    <IconWrapper {...props} >
	    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.6749 18.637C17.5728 18.6225 17.4416 18.6225 17.3249 18.637C14.7582 18.5495 12.7166 16.4495 12.7166 13.8683C12.7166 11.2287 14.8457 9.08496 17.4999 9.08496C20.1395 9.08496 22.2832 11.2287 22.2832 13.8683C22.2686 16.4495 20.2416 18.5495 17.6749 18.637Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M27.3292 28.2629C24.7334 30.64 21.2917 32.0837 17.5001 32.0837C13.7084 32.0837 10.2667 30.64 7.6709 28.2629C7.81673 26.8921 8.69173 25.5504 10.2521 24.5004C14.248 21.8462 20.7813 21.8462 24.748 24.5004C26.3084 25.5504 27.1834 26.8921 27.3292 28.2629Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.5001 32.0837C25.5542 32.0837 32.0834 25.5545 32.0834 17.5003C32.0834 9.44617 25.5542 2.91699 17.5001 2.91699C9.44593 2.91699 2.91675 9.44617 2.91675 17.5003C2.91675 25.5545 9.44593 32.0837 17.5001 32.0837Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>

    </IconWrapper>
  )
}

export default IconItem
